import http from '@/util/http-common'

class CategoryDataService {
    getAll () {
        return http.get('/categories')
    }

    create (data) {
        return http.post('/categories', data)
    }

    update (data) {
        return http.patch(`/categories/${data.id}`, data)
    }

    delete (id) {
        return http.delete(`/categories/${id}`)
    }

    getAllWithProductsWithoutZone (data) {
        return http.get('/categories/products', { params: { search: data } })
    }
}

export default new CategoryDataService()
