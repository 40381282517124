<template>
  <v-container id="products" fluid tag="section">
    <div class="row">
      <v-col cols="12">
        <app-btn class="mr-2" @click="openZoneDialog">
          <v-icon>mdi-view-grid-plus</v-icon> Bays
        </app-btn>
        <app-btn @click="openCategoryDialog">
          <v-icon>mdi-view-grid-plus</v-icon> Categories
        </app-btn>
        <v-dialog v-model="dialogZone" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Bays</span>
            </v-card-title>
            <v-card-text>
              <v-row class="mx-2 mt-2">
                <v-col md="10">
                  <v-text-field v-model="zoneName" label="Bay" />
                </v-col>
                <v-col md="2" class="mt-2">
                  <v-btn color="primary" @click="addZone">
                    {{ btn }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-card
                v-for="zone in zones"
                :key="zone.id"
                color="#E8F5E9"
                class="ma-1 pa-1"
              >
                <v-row class="ma-1">
                  <v-col md="10">
                    {{ zone.name }}
                  </v-col>
                  <v-col md="2">
                    <v-icon
                      small
                      color="info"
                      class="mr-2"
                      @click="editZone(zone)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon small color="error" @click="deleteZone(zone)">
                      mdi-delete
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="dialogZone = false">
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogCategory" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Categories</span>
            </v-card-title>
            <v-card-text>
              <v-row class="mx-2 mt-2">
                <v-col md="10">
                  <v-text-field v-model="categoryName" label="Category Name" />
                </v-col>
                <v-col md="2" class="mt-2">
                  <v-btn color="primary" @click="addCategory">
                    {{ btn }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-card
                v-for="category in categories"
                :key="category.id"
                color="#E8F5E9"
                class="ma-1 pa-1"
              >
                <v-row class="ma-1">
                  <v-col md="10">
                    {{ category.name }}
                  </v-col>
                  <v-col md="2">
                    <v-icon
                      small
                      color="info"
                      class="mr-2"
                      @click="editCategory(category.id, category.name)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      color="error"
                      @click="deleteCategory(category.id)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="dialogCategory = false">
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="5">
        <app-card class="pa-4">
          <div class="text-h5 mb-5">Unallocated Products</div>
          <!-- <v-text-field
            v-if="unallocatedProducts && unallocatedProducts.length > 0"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mb-2"
            hide-details
            label="Search records"
            single-line
            style="max-width: 250px"
          /> -->
          <div v-if="!(unallocatedProducts && unallocatedProducts.length > 0)">
            <p class="text--secondary ml-5">
              {{
                isLoading
                  ? "Please wait... loading unallocated products."
                  : "There are no unallocated Products."
              }}
            </p>
          </div>
          <draggable
            class="list-group"
            tag="div"
            group="products"
            :list="unallocatedProducts"
          >
            <v-card
              v-for="product in unallocatedProducts"
              :key="product.productId"
              color="#E8F5E9"
              class="pa-1"
            >
              <p class="ma-2" style="font-size: 0.875rem !important">
                {{ product.ean + " _ " + product.sku + " _ " + product.name }}
              </p>
            </v-card>
          </draggable>
        </app-card>
      </v-col>
      <v-col cols="12" md="7">
        <app-card class="pa-4">
          <div class="text-h5 mb-5">Bays</div>
          <div v-if="!(zones && zones.length > 0)">
            <p class="text--secondary ml-5">
              {{
                isLoading
                  ? "Please wait..Loading bay details. "
                  : "There are no bays."
              }}
            </p>
          </div>
          <app-tabs color="primary" vertical icons-and-text>
            <template v-for="zone in zones">
              <v-tab
                :key="zone.bayId"
                class="text-none"
                @click="getProducts(zone)"
              >
                {{ zone.name }}
              </v-tab>
              <v-tab-item :key="`tab-item-${zone.bayId}`" class="ml-3">
                <p class="text--secondary ml-5" v-if="loadingProducts">
                  Please wait..Loading bay products details.
                </p>
                <app-tabs color="primary" vertical icons-and-text>
                  <template v-for="category in zone.categories">
                    <v-tab :key="category.name" class="text-none">
                      {{ category.name }}
                    </v-tab>
                    <v-tab-item :key="`tab-item-${category.name}`" class="ml-3">
                      <draggable
                        class="list-group"
                        :list="category.products"
                        tag="div"
                        group="products"
                        @change="dragEvent(true, zone.bayId, $event)"
                        v-if="category.products && category.products.length > 0"
                      >
                        <v-card
                          v-for="item in category.products"
                          :key="item.productId"
                          color="#E8F5E9"
                          class="pa-1"
                        >
                          <p
                            class="ma-2"
                            style="font-size: 0.875rem !important"
                          >
                            {{
                              item.ean + " _ " + item.sku + " _ " + item.name
                            }}
                          </p>
                        </v-card>
                      </draggable>
                    </v-tab-item>
                  </template>
                </app-tabs>
              </v-tab-item>
            </template></app-tabs
          ></app-card
        ></v-col
      >
    </div></v-container
  >
</template>
          </app-tabs>
        </app-card>
      </v-col>
    </div>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import ProductDataService from "@/services/ProductDataService";
import CategoryDataService from "@/services/CategoryDataService";
import ZoneDataService from "@/services/ZoneDataService";

export default {
  name: "Bays",
  components: {
    draggable,
  },
  data: () => ({
    isLoading: false,
    loadingProducts: false,
    dialogZone: false,
    dialogCategory: false,
    zoneName: "",
    currentZone: 0,
    categoryName: "",
    currentCategory: 0,
    btn: "Add",
    categories: [],
    zones: [],
    unallocatedProducts: [],
    search: null,
    awaitingSearch: null,
  }),

  computed: {},

  watch: {
    search: {
      handler() {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = setTimeout(() => {
          this.getAllWithProductsWithoutZone(this.search);
        }, 1000); // 1 sec delay
      },
    },
  },

  created() {
    this.isLoading = true;
    this.$http.get("Bays/UnallocatedProducts").then((response) => {
      this.unallocatedProducts = response.data.data;
    });

    this.$http
      .get("Bays")
      .then((response) => {
        let data = response.data.data;
        this.zones = data.sort((a, b) => {
          let A = a.name.split(" ")[1];
          let B = b.name.split(" ")[1];
          return A - B;
        });

        if (this.zones && this.zones.length > 0) {
          this.getProducts(this.zones[0]);
        }
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },

  methods: {
    getProducts(zone) {
      if (zone) {
        if (!(zone.products && zone.products.length > 0)) {
          this.loadingProducts = true;
          this.$http
            .get(`Bays/${zone.bayId}/Products`)
            .then((response) => {
              let data = response.data.data;
              let categories = data.reduce((categories, product) => {
                if (!categories.hasOwnProperty(product.category.name)) {
                  categories[product.category.name] = [];
                  categories[product.category.name].push(product);
                } else {
                  categories[product.category.name].push(product);
                }
                return categories;
              }, {});

              zone.categories = Object.keys(categories).map((x) => {
                return { name: x, products: categories[x] };
              });

              this.loadingProducts = false;
            })
            .catch(() => {
              this.loadingProducts = false;
            });
        }
      }
    },
    openZoneDialog() {
      this.dialogZone = true;
      this.btn = "Add";
      this.zoneName = "";
    },
    addZone() {
      if (this.btn === "Edit") {
        var data = {
          id: this.currentZone,
          name: this.zoneName,
        };

        ZoneDataService.update(data).then((response) => {
          var index = this.zones.findIndex((x) => x.id === data.id);
          data.categories = this.zones[index].categories;
          this.zones.splice(index, 1, data);
        });
        this.btn = "Add";
      } else {
        var data = {
          Name: this.zoneName,
        };
        ZoneDataService.create(data).then((response) => {
          var categories = [];
          this.categories.forEach((x) => {
            var temp = {
              id: x.id,
              name: x.name,
              products: [],
            };
            categories.push(temp);
          });
          var zone = {
            id: response.data.id,
            name: response.data.name,
            categories: categories,
          };
          this.zones.push(zone);
        });
      }
      this.zoneName = "";
    },
    editZone(zone) {
      this.btn = "Edit";
      this.zoneName = zone.name;
      this.currentZone = zone.id;
    },
    deleteZone(zone) {
      var productsCount = 0;
      zone.categories.forEach((element) => {
        productsCount = productsCount + element.products.length;
      });
      if (productsCount > 0) {
        alert("This zone have products");
      } else {
        ZoneDataService.delete(zone.id).then((response) => {
          var index = this.zones.findIndex((x) => x.id === zone.id);
          this.zones.splice(index, 1);
        });
      }
    },
    openCategoryDialog() {
      if (!(this.categories && this.categories.length > 0)) {
        this.$http.get("Categories").then((response) => {
          this.categories = response.data.data;
        });
      }

      this.dialogCategory = true;
      this.btn = "Add";
      this.categoryName = "";
    },
    addCategory() {
      if (this.btn === "Edit") {
        var data = {
          id: this.currentCategory,
          name: this.categoryName,
        };

        CategoryDataService.update(data).then((response) => {
          var index = this.categories.findIndex((x) => x.id === data.id);
          var tempObj = {
            id: data.id,
            name: data.name,
            products: this.categories[index].products,
          };
          this.categories.splice(index, 1, tempObj);

          this.zones.forEach((x) => {
            var tempIndex = x.categories.findIndex((x) => x.id === data.id);
            var tempObj1 = {
              id: data.id,
              name: data.name,
              products: x.categories[tempIndex].products,
            };
            x.categories.splice(index, 1, tempObj1);
          });
        });
        this.btn = "Add";
      } else {
        var data = {
          Name: this.categoryName,
        };
        CategoryDataService.create(data).then((response) => {
          this.categories.push(response.data);
          this.zones.forEach((x) => {
            x.categories.push(response.data);
          });
        });
      }
      this.categoryName = "";
    },
    editCategory(id, name) {
      this.btn = "Edit";
      this.categoryName = name;
      this.currentCategory = id;
    },
    deleteCategory(id) {
      var UnallocatedProductsCount = this.categories.find((x) => x.id === id)
        .products.length;
      var allocatedProductsCount = 0;
      this.zones.forEach((element) => {
        allocatedProductsCount =
          allocatedProductsCount +
          element.categories.find((x) => x.id === id).products.length;
      });

      if (UnallocatedProductsCount > 0 || allocatedProductsCount > 0) {
        alert("This category have products");
      } else {
        CategoryDataService.delete(id).then((response) => {
          // unallocated
          var index = this.categories.findIndex((x) => x.id === id);
          this.categories.splice(index, 1);
          // allocated
          this.zones.forEach((x) => {
            var index2 = x.categories.findIndex((x) => x.id === id);
            x.categories.splice(index2, 1);
          });
        });
      }
    },

    dragEvent(isAdded, zoneID, evt) {
      if (evt.hasOwnProperty("added")) {
        let productID = evt.added.element.productId;
        if (isAdded) {
          this.$http
            .post(
              "Products/Update",
              {
                bayId: zoneID,
              },
              { params: { productId: productID } }
            )
            .then((response) => {
              if (response.data.success) {
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            });
        }
      }
    },
  },
};
</script>
<style scoped></style>
